import { Component } from 'react';

import './stylesheets/App.css';
import './stylesheets/reset.css';
import './stylesheets/modal.css';
import './stylesheets/styles.css';

import { AuthState } from '@aws-amplify/ui-components';
import { Hub, HubPayload } from '@aws-amplify/core';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import ReactGA from 'react-ga4';

import { callGraphQL } from './components/GraphQLTypescript';

import NavBar from './components/NavBar';

import PopupModal from './components/PopupModal';
import PurchaseItemModalContent from './components/PurchaseItemModalContent';
import SignInModalContent from './components/SignInModalContent';
import UserInfoModalContent from './components/UserInfoModalContent';

import StackableHelpers from './helpers/StackableHelpers';
import { PresaleItemData } from './components/PresaleItem';
import {
  GetUserQuery,
  GetInventoryQuery,
  User,
  Inventory,
  Stackable,
  PurchaseStackableMutation,
} from './API';
import { getInventory, getUser } from './graphql/queries';
import { purchaseStackable } from './graphql/mutations';

import GameSection from './components/GameSection';
import gameData from './data/gamesData.json';

import HomePage from './pages/Home';
import FAQPage from './pages/FAQ';
import TOSPage from './pages/TOS';
import LinkagePage from './pages/Linkage';

// @ts-ignore
import Heartbeat from 'react-heartbeat';
import { Switch, Route, Link } from 'react-router-dom';
import AboutPage from './pages/About';

interface AppProps {}

interface AppState {
  authState: AuthState;
  user?: User;
  devWallet?: Inventory;
  tokenizerUserUrl?: String;
  showModal: boolean;
  selectedItem?: PresaleItemData;
  signInView: AuthState.SignIn | AuthState.SignUp | undefined;
  showUserInfo: User | undefined;
  lastError?: String;
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      authState: AuthState.SignIn,
      showModal: false,
      signInView: undefined,
      showUserInfo: undefined,
    };

    this.queryUser = this.queryUser.bind(this);
    this.queryDeveloper = this.queryDeveloper.bind(this);
    this.heartBeatFunction = this.heartBeatFunction.bind(this);
    this.handleAuthChange = this.handleAuthChange.bind(this);
    this.purchaseItem = this.purchaseItem.bind(this);
    this.goToExchange = this.goToExchange.bind(this);
    this.openPurchaseModal = this.openPurchaseModal.bind(this);
    this.openSignInModal = this.openSignInModal.bind(this);
    this.openSignUpModal = this.openSignUpModal.bind(this);
    this.openUserInfoModal = this.openUserInfoModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    ReactGA.initialize('G-GLM0C5FZY3');
  }

  async componentDidMount() {
    this.queryDeveloper();
    Hub.listen('auth', (data) => {
      this.handleAuthChange(data.payload);
    });

    try {
      const data = await Auth.currentAuthenticatedUser();
      this.setState({
        authState: AuthState.SignedIn,
      });
      if (data?.attributes) {
        this.queryUser(data.attributes.sub);
      }
    } catch (err) {
      this.setState({
        authState: AuthState.SignedOut,
      });
    }
  }

  async queryUser(userId: string) {
    const userData = await callGraphQL<GetUserQuery>(
      getUser,
      { id: userId },
      GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    );
    if (userData.data?.getUser) {
      this.setState({
        user: userData.data.getUser,
      });
    }
  }

  async queryDeveloper() {
    const devData = await callGraphQL<GetInventoryQuery>(
      getInventory,
      {},
      GRAPHQL_AUTH_MODE.API_KEY,
    );
    if (devData.data?.getInventory) {
      this.setState({
        devWallet: devData.data.getInventory,
      });
    }
  }

  async heartBeatFunction() {
    if (this.state.user) this.queryUser(this.state.user.id);
    this.queryDeveloper();
  }

  handleAuthChange(payload: HubPayload) {
    switch (payload.event) {
      case 'signIn':
        this.setState({
          authState: AuthState.SignedIn,
        });
        var ga_user_id = this.state.user ? this.state.user.id : null;
        ReactGA.set({
          userId: ga_user_id,
        });
        ReactGA.event({
          category: 'Sign In',
          action: 'User signed in.',
        });
        if (payload.data.attributes)
          this.queryUser(payload.data.attributes.sub);
        this.closeModal();
        break;
      case 'signOut':
        this.setState({
          authState: AuthState.SignedOut,
          user: undefined,
        });
        ReactGA.event({
          category: 'Sign Out',
          action: 'User signed out.',
        });
        break;
    }
  }

  async purchaseItem(itemData: Stackable) {
    this.setState({ lastError: undefined });
    var params = {
      walletId: this.state.user?.walletId,
      stackableId: itemData.stackable_type_id,
      emailAddress: this.state.user?.email,
    };
    const purchase_response = await callGraphQL<PurchaseStackableMutation>(
      purchaseStackable,
      params,
      GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    );
    ReactGA.event({
      category: 'Purchase Success',
      action: 'User made a successful Purchase!',
    });
    if (purchase_response.data?.purchaseStackable?.error) {
      this.setState({
        lastError: purchase_response.data?.purchaseStackable?.error,
      });
    }
    await this.heartBeatFunction();
  }

  goToExchange() {
    console.log('env var', process.env);

    if (this.state.user?.isLinked) {
      window.open(process.env.REACT_APP_FORTE_EXCHANGE_SERVER, '_blank');
      ReactGA.event({
        category: 'Exchange',
        action: 'link_to_portal_dashboard',
      });
    } else if (this.state.user?.exchangeUrl) {
      window.open(this.state.user.exchangeUrl, '_blank');
      ReactGA.event({
        category: 'Exchange',
        action: 'account_link_url',
      });
    }
  }

  openPurchaseModal(itemData: PresaleItemData): void {
    this.setState({
      showModal: true,
      selectedItem: itemData,
      signInView: undefined,
      showUserInfo: undefined,
      lastError: undefined,
    });
  }

  openSignInModal(): void {
    this.setState({
      showModal: true,
      selectedItem: undefined,
      signInView: AuthState.SignIn,
      showUserInfo: undefined,
    });
  }

  openSignUpModal(): void {
    this.setState({
      showModal: true,
      selectedItem: undefined,
      signInView: AuthState.SignUp,
      showUserInfo: undefined,
    });
  }

  openUserInfoModal(): void {
    this.setState({
      showModal: true,
      selectedItem: undefined,
      signInView: undefined,
      showUserInfo: this.state.user,
    });
  }

  closeModal(): void {
    this.setState({
      showModal: false,
      selectedItem: undefined,
      signInView: undefined,
      showUserInfo: undefined,
    });
  }

  render() {
    return (
      <div>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"
          rel="stylesheet"
        />

        <NavBar
          openSignInModal={this.openSignInModal}
          openSignUpModal={this.openSignUpModal}
          openUserInfoModal={this.openUserInfoModal}
          goToExchangeFunction={this.goToExchange}
          authState={this.state.authState}
          userData={this.state.user}
        />

        <Switch>
          {' '}
          {/* The Switch decides which component to show based on the current URL.*/}
          <Route
            exact
            path="/"
            render={() => (
              <HomePage>
                <div className="games">
                  {gameData.map((data) => (
                    <GameSection
                      key={data.name}
                      userStackableData={this.state.user?.wallet?.stackables}
                      gameData={data}
                      devWallet={this.state.devWallet}
                      openModalFunction={this.openPurchaseModal}
                    />
                  ))}
                </div>
                <Link
                  style={{
                    position: 'absolute',
                    left: '50%',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                  }}
                  to="/tos"
                >
                  Terms of Service
                </Link>
              </HomePage>
            )}
          />
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/tos" component={TOSPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route
            exact
            path="/linkage"
            render={() => <LinkagePage user={this.state.user} />}
          />
        </Switch>

        {/* Start Modal */}
        <PopupModal
          showModal={this.state.showModal}
          closeModalFunction={this.closeModal}
        >
          <PurchaseItemModalContent
            itemData={this.state.selectedItem}
            stackableData={StackableHelpers.stackableDataForSKU(
              this.state.devWallet?.stackables,
              this.state.selectedItem?.sku,
            )}
            userData={this.state.user}
            errorMsg={this.state.lastError}
            goToExchangeFunction={this.goToExchange}
            purchaseItemFunction={this.purchaseItem}
            closeModalFunction={this.closeModal}
            openSigninFunction={this.openSignInModal}
          />
          <SignInModalContent
            signInView={this.state.signInView}
            closeModalFunction={this.closeModal}
          />
          <UserInfoModalContent
            showUserInfoData={this.state.showUserInfo}
            closeModalFunction={this.closeModal}
          />
        </PopupModal>

        <Heartbeat
          heartbeatFunction={this.heartBeatFunction}
          heartbeatInterval={60000}
        />
      </div>
    );
  }
}

export default App;
