/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const purchaseStackable = /* GraphQL */ `
  mutation PurchaseStackable(
    $stackableId: String
    $walletId: String
    $emailAddress: String
  ) {
    purchaseStackable(
      stackableId: $stackableId
      walletId: $walletId
      emailAddress: $emailAddress
    ) {
      status
      body {
        status
        user_id_from
        user_id_to
        amount
        stackable_type_id
      }
      error
    }
  }
`;
export const createBadge = /* GraphQL */ `
  mutation CreateBadge(
    $input: CreateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    createBadge(input: $input, condition: $condition) {
      id
      legacyId
      title
      achievementTitle
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBadge = /* GraphQL */ `
  mutation UpdateBadge(
    $input: UpdateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    updateBadge(input: $input, condition: $condition) {
      id
      legacyId
      title
      achievementTitle
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBadge = /* GraphQL */ `
  mutation DeleteBadge(
    $input: DeleteBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    deleteBadge(input: $input, condition: $condition) {
      id
      legacyId
      title
      achievementTitle
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createKongpanion = /* GraphQL */ `
  mutation CreateKongpanion(
    $input: CreateKongpanionInput!
    $condition: ModelKongpanionConditionInput
  ) {
    createKongpanion(input: $input, condition: $condition) {
      id
      legacyId
      title
      description
      userKongpanionsCount
      chipValue
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateKongpanion = /* GraphQL */ `
  mutation UpdateKongpanion(
    $input: UpdateKongpanionInput!
    $condition: ModelKongpanionConditionInput
  ) {
    updateKongpanion(input: $input, condition: $condition) {
      id
      legacyId
      title
      description
      userKongpanionsCount
      chipValue
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteKongpanion = /* GraphQL */ `
  mutation DeleteKongpanion(
    $input: DeleteKongpanionInput!
    $condition: ModelKongpanionConditionInput
  ) {
    deleteKongpanion(input: $input, condition: $condition) {
      id
      legacyId
      title
      description
      userKongpanionsCount
      chipValue
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createStickerPack = /* GraphQL */ `
  mutation CreateStickerPack(
    $input: CreateStickerPackInput!
    $condition: ModelStickerPackConditionInput
  ) {
    createStickerPack(input: $input, condition: $condition) {
      id
      legacyId
      title
      description
      distributionType
      price
      shinyOwnedCount
      commonOwnedCount
      backgroundCss
      sku
      visibleAt
      createdAt
      updatedAt
      owner
      stickers {
        items {
          id
          legacyId
          title
          url
          shiny
          stickerPackId
          createdAt
          updatedAt
          stickerPack {
            id
            legacyId
            title
            description
            distributionType
            price
            shinyOwnedCount
            commonOwnedCount
            backgroundCss
            sku
            visibleAt
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateStickerPack = /* GraphQL */ `
  mutation UpdateStickerPack(
    $input: UpdateStickerPackInput!
    $condition: ModelStickerPackConditionInput
  ) {
    updateStickerPack(input: $input, condition: $condition) {
      id
      legacyId
      title
      description
      distributionType
      price
      shinyOwnedCount
      commonOwnedCount
      backgroundCss
      sku
      visibleAt
      createdAt
      updatedAt
      owner
      stickers {
        items {
          id
          legacyId
          title
          url
          shiny
          stickerPackId
          createdAt
          updatedAt
          stickerPack {
            id
            legacyId
            title
            description
            distributionType
            price
            shinyOwnedCount
            commonOwnedCount
            backgroundCss
            sku
            visibleAt
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteStickerPack = /* GraphQL */ `
  mutation DeleteStickerPack(
    $input: DeleteStickerPackInput!
    $condition: ModelStickerPackConditionInput
  ) {
    deleteStickerPack(input: $input, condition: $condition) {
      id
      legacyId
      title
      description
      distributionType
      price
      shinyOwnedCount
      commonOwnedCount
      backgroundCss
      sku
      visibleAt
      createdAt
      updatedAt
      owner
      stickers {
        items {
          id
          legacyId
          title
          url
          shiny
          stickerPackId
          createdAt
          updatedAt
          stickerPack {
            id
            legacyId
            title
            description
            distributionType
            price
            shinyOwnedCount
            commonOwnedCount
            backgroundCss
            sku
            visibleAt
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const createSticker = /* GraphQL */ `
  mutation CreateSticker(
    $input: CreateStickerInput!
    $condition: ModelStickerConditionInput
  ) {
    createSticker(input: $input, condition: $condition) {
      id
      legacyId
      title
      url
      shiny
      stickerPackId
      createdAt
      updatedAt
      stickerPack {
        id
        legacyId
        title
        description
        distributionType
        price
        shinyOwnedCount
        commonOwnedCount
        backgroundCss
        sku
        visibleAt
        createdAt
        updatedAt
        owner
        stickers {
          items {
            id
            legacyId
            title
            url
            shiny
            stickerPackId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateSticker = /* GraphQL */ `
  mutation UpdateSticker(
    $input: UpdateStickerInput!
    $condition: ModelStickerConditionInput
  ) {
    updateSticker(input: $input, condition: $condition) {
      id
      legacyId
      title
      url
      shiny
      stickerPackId
      createdAt
      updatedAt
      stickerPack {
        id
        legacyId
        title
        description
        distributionType
        price
        shinyOwnedCount
        commonOwnedCount
        backgroundCss
        sku
        visibleAt
        createdAt
        updatedAt
        owner
        stickers {
          items {
            id
            legacyId
            title
            url
            shiny
            stickerPackId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteSticker = /* GraphQL */ `
  mutation DeleteSticker(
    $input: DeleteStickerInput!
    $condition: ModelStickerConditionInput
  ) {
    deleteSticker(input: $input, condition: $condition) {
      id
      legacyId
      title
      url
      shiny
      stickerPackId
      createdAt
      updatedAt
      stickerPack {
        id
        legacyId
        title
        description
        distributionType
        price
        shinyOwnedCount
        commonOwnedCount
        backgroundCss
        sku
        visibleAt
        createdAt
        updatedAt
        owner
        stickers {
          items {
            id
            legacyId
            title
            url
            shiny
            stickerPackId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      walletId
      kongUserId
      kongUserName
      kreds
      mobileSpendLevel
      totalKredsPurchased
      totalDollarsSpent
      lastGamePlayedAt
      lastPurchaseDate
      parentalConsent
      kongMetaLevel
      email
      isLinked
      premium
      wallet {
        unique_count
        stackable_type_count
        coins {
          symbol
          balance
        }
        stackables {
          stackable_type_id
          balance
          metadata {
            asset_category
            name
            price
            sku
            quantity
          }
          key
        }
        uniques {
          unique_id
          key
          unique_type
          metadata
        }
      }
      transactions {
        transaction_id
        user_id_from
        user_id_to
        type
        data {
          amount
          token_id
        }
        timestamp
      }
      exchangeUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      walletId
      kongUserId
      kongUserName
      kreds
      mobileSpendLevel
      totalKredsPurchased
      totalDollarsSpent
      lastGamePlayedAt
      lastPurchaseDate
      parentalConsent
      kongMetaLevel
      email
      isLinked
      premium
      wallet {
        unique_count
        stackable_type_count
        coins {
          symbol
          balance
        }
        stackables {
          stackable_type_id
          balance
          metadata {
            asset_category
            name
            price
            sku
            quantity
          }
          key
        }
        uniques {
          unique_id
          key
          unique_type
          metadata
        }
      }
      transactions {
        transaction_id
        user_id_from
        user_id_to
        type
        data {
          amount
          token_id
        }
        timestamp
      }
      exchangeUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      walletId
      kongUserId
      kongUserName
      kreds
      mobileSpendLevel
      totalKredsPurchased
      totalDollarsSpent
      lastGamePlayedAt
      lastPurchaseDate
      parentalConsent
      kongMetaLevel
      email
      isLinked
      premium
      wallet {
        unique_count
        stackable_type_count
        coins {
          symbol
          balance
        }
        stackables {
          stackable_type_id
          balance
          metadata {
            asset_category
            name
            price
            sku
            quantity
          }
          key
        }
        uniques {
          unique_id
          key
          unique_type
          metadata
        }
      }
      transactions {
        transaction_id
        user_id_from
        user_id_to
        type
        data {
          amount
          token_id
        }
        timestamp
      }
      exchangeUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
