import { Stackable } from "../API"

const StackableHelpers = {

  stackableDataForSKU(stackables:(Stackable|null)[]|null|undefined, sku: string|null|undefined): Stackable | undefined {
    var data = undefined;
    if (stackables) {
      stackables.forEach(stackable => {
        if (stackable?.metadata?.sku === sku ||
          (stackable?.metadata?.sku === null && sku === "")) //THIS IS FOR TEST SKU
        {
          data = stackable;
        }
      });
    }
    return data;
  }

}

export default StackableHelpers;