import { Component, ReactElement } from 'react';

import { Document, Page, pdfjs } from 'react-pdf'

interface TOSProps {
}

interface TOSState {
  numberPages: number;
}

class TOSPage extends Component<TOSProps, TOSState> {
  constructor(props: TOSProps) {
    super(props);
    this.state = {
      numberPages: 0
    };

    pdfjs.GlobalWorkerOptions.workerSrc =
      `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.pages = this.pages.bind(this);
  }

  onDocumentLoadSuccess(info:any) {
    this.setState({
      numberPages: info.numPages
    });
    console.log(info.numPages);
  }

  pages():ReactElement[] {
    console.log("Rendering pages");
    var pages: ReactElement[] = [];
    for (let i = 0; i < this.state.numberPages; i++) {
      pages.push(<Page pageNumber={i + 1} />);
    }
    return pages;
  }

  render() {
    return (
      <div className="centered-main-div">
        <Document file="Kong.TermsofService.20210728.PUBLISHED.pdf" onLoadSuccess={this.onDocumentLoadSuccess}>
          <Page pageNumber={1} />
          <Page pageNumber={2} />
          <Page pageNumber={3} />
          <Page pageNumber={4} />
          <Page pageNumber={5} />
          <Page pageNumber={6} />
          <Page pageNumber={7} />
          <Page pageNumber={8} />
          <Page pageNumber={9} />
          <Page pageNumber={10} />
          <Page pageNumber={11} />
          <Page pageNumber={12} />
          <Page pageNumber={13} />
          <Page pageNumber={14} />
          <Page pageNumber={15} />
          <Page pageNumber={16} />
        </Document>
      </div>
    );
  }

}

export default TOSPage;