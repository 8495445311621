import { callGraphQL } from '../components/GraphQLTypescript';
import { Component } from 'react';
import { getTokenizedUserUrl } from '../graphql/queries';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { User, GetTokenizedUserUrlQuery } from "../API"

interface LinkagePageProps {
  user?: User;
}

interface LinkagePageState {
  tokenizerUserUrl: string | null | undefined

}

class LinkagePage extends Component<LinkagePageProps, LinkagePageState> {
  constructor(props: LinkagePageProps) {
    super(props);
    this.state = {
      tokenizerUserUrl: "#"
    };
    this.queryTokenizedUserUrl = this.queryTokenizedUserUrl.bind(this);

  }

  async componentDidMount() {
    if(this.props.user) {
      this.queryTokenizedUserUrl(this.props.user?.id);
    }
  }

  async queryTokenizedUserUrl(userId: string) {
    const token = await callGraphQL<GetTokenizedUserUrlQuery>(getTokenizedUserUrl, {id: userId}, GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS);
    this.setState({
      tokenizerUserUrl: token.data?.getTokenizedUserUrl
    });
    
  }

  render() {
    console.log(this.props.user?.kongUserName);
    console.log("token",this.state.tokenizerUserUrl);
    let link =  <div className="centered-main-div"><h2>KONGREGATE.Com Account Linking</h2>
                <br />
                <p><strong>Clicking the link below confirms you are the owner of a Kongregate.com Account and want to do a one-time import of your Goodies</strong><br />
                </p> <a href="https://kongregate.com">Take me to Kongregate.com and import my goodies</a></div>
    let alreadyLinked =  <div className="centered-main-div">
    <h2>KONGREGATE.Com Account Already Linked To {this.props.user?.kongUserName}</h2>
    <br /></div>
    return(
        <div>
          {this.props.user?.kongUserName ? alreadyLinked : link}
        </div>
    )
  }

}

export default LinkagePage;