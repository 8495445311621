import { Component } from 'react';

interface AboutPageProps {}

interface AboutPageState {}

class AboutPage extends Component<AboutPageProps, AboutPageState> {
  constructor(props: AboutPageProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section className="hero">
          <h3 className="subtitle"> THE NEXT STAGE OF GAMING</h3>
          <h1 className="title">KONGREGATE.io</h1>
        </section>
        <div className="centered-main-div">
          <br />
          <p>
            Created by Kongregate, KONGREGATE.io is a celebration and evolution
            of Kongregate’s 15 years in the gaming space and will redefine what
            a web gaming platform is for today’s players.
          </p>
          <br />
          <p>
            What started as a mission to unite gamers on www.kongregate.com has
            evolved to take advantage of the latest technology to give players a
            superior gaming experience.
          </p>
          <br />
          <p>
            KONGREGATE.io will be launching later this year with fan favorite
            titles including Bit Heroes, Spellstone, and our latest addition to
            the portfolio, surviv.io. And maybe a few additional surprises for
            die hard Kongregate fans… 🤫
          </p>
          <br />
          <p>Stay tuned for more announcements coming very soon...</p>
        </div>
      </div>
    );
  }
}

export default AboutPage;
