/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "ForteUserUpdates",
            "endpoint": "https://nyx1mr7820.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://pum6aha7nrdc5cxwvaleedo76y.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-blq7agwm45dnjlwwvvangsmk4e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_E4cRTZPn7",
    "aws_user_pools_web_client_id": "1it039376vs821k4088bhjlhpc",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
