import { API, graphqlOperation, GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Observable } from '../../node_modules/zen-observable-ts';

export interface GraphQLOptions {
  input?: object;
  variables?: {};
  authMode?: GRAPHQL_AUTH_MODE;
}

export interface SubscriptionResponse<T> {
  provider?: string;
  value: {
    data?: T
  };
}

export async function callGraphQL<T>(query: any, options?: {}, auth_mode?: GRAPHQL_AUTH_MODE): Promise<GraphQLResult<T>> {
  return (await API.graphql({ query: query, variables: options, authMode: auth_mode })) as GraphQLResult<T>;
}

export function subscribeGraphQL<T extends object>(query: any, options?: {}): Observable<SubscriptionResponse<T>> {
  return (API.graphql(graphqlOperation(query, options))) as Observable<SubscriptionResponse<T>>;
}

