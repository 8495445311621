import React, { Component } from 'react';

interface PopupModalProps {
  showModal: boolean;
  closeModalFunction: () => void;
}

interface PopupModalState {
}

class PopupModal extends Component<PopupModalProps, PopupModalState> {
  private wrapperRef: React.RefObject<HTMLDivElement>;
  constructor(props: PopupModalProps) {
    super(props);
    this.state = {
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  /**
  * Alert if clicked on outside of element
  */
  handleClickOutside(event:MouseEvent) {
    if (this.props.showModal && 
        this.wrapperRef.current && 
        !this.wrapperRef.current.children[0].contains(event.target as Node)) {
      this.props.closeModalFunction();
    }
  }

  handleClose() {
    this.props.closeModalFunction();
  }

  render() {
    return (
      <div
        id="all-modal"
        className="modal"
        style={{ display: this.props.showModal ? 'block' : 'none' }}
        ref={this.wrapperRef}
      >
        {this.props.children}
      </div>
    );
  }

}

export default PopupModal;