import React, { Component } from 'react';

import StackableHelpers from '../helpers/StackableHelpers';

import { Stackable } from '../API'

interface PresaleItemProps {
  key: String;
  userStackablesData?: (Stackable | null)[] | null | undefined;
  itemData: PresaleItemData;
  stackableData?: Stackable;
  styleClass?: string;
  openModalFunction?: (itemData: PresaleItemData) => void;
  showBuy: boolean;
}

interface PresaleItemState {
}

export type PresaleItemData = object & {
  id: number;
  name: string;
  sku: string;
  img: string;
  desc: string;
  cost: number;
  tags: string[];
}

class PresaleItem extends Component<PresaleItemProps, PresaleItemState> {
  constructor(props: PresaleItemProps) {
    super(props);
    this.state = {
    };

    this.itemImage = this.itemImage.bind(this);
    this.remainingBalance = this.remainingBalance.bind(this);
    this.numOwned = this.numOwned.bind(this);
    this.price = this.price.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  itemImage(): string {
    if (this.props.itemData)
      return require("../images/item-assets/" + this.props.itemData.img).default;

    return "";
  }

  remainingBalance(): string {
    return (this.props.stackableData) ? this.props.stackableData.balance + "" : "??";
  }

  numOwned(): number {
    return StackableHelpers.stackableDataForSKU(this.props.userStackablesData, this.props.stackableData?.metadata?.sku)?.balance || 0;
  }

  price(): string {
    if (this.props.stackableData?.metadata?.price === null) return "1";//THIS IS FOR TEST SKU

    return (this.props.stackableData) ? this.props.stackableData.metadata?.price + "" : "??";
  }

  priceUsd(): string {
    var price = "1";
    if (this.props.stackableData?.metadata?.price === null) 
      price = "??";
    else{
      var priceInt = this.props.stackableData?.metadata?.price || "10";
      price = (parseInt(priceInt) * 0.1).toString();
    } 
    return price;
  }


  handleClick(event:React.MouseEvent) {
    event.preventDefault();
    if (this.props.openModalFunction)
      this.props.openModalFunction(this.props.itemData);
  }

  render() {
    const itemImageClass = this.props.showBuy ? "item-image" : "" ;
    return (
      <article className="item" key={this.props.itemData.name}>
        <div className="item-image" id={(this.props.styleClass || "")}>
          <img src={this.itemImage()} alt={this.props.itemData.name} className={itemImageClass}/>
          {
            this.props.itemData.tags.map((tag, i) => (
              <div className="item-tag" key={`tag-${this.props.itemData.id}-${i}`}>
                {tag}
              </div>
            ))
          }
        </div>
        <div className="item-details">
          <h1>{this.props.itemData.name}</h1>
          <p className="item-desc" dangerouslySetInnerHTML={{ __html: this.props.itemData.desc}} />
          <p className="item-rarity">{this.remainingBalance()} remaining</p>
          {
            this.numOwned() > 0 &&
            <p className="item-owned">You Own {this.numOwned()}!</p>
          }
          {
            this.props.showBuy &&
            <button className="buy" id="modal-btn" onClick={this.handleClick}>
              <a href="/#" className="primary-button buy-button">Buy for {this.price()} Koins</a>
            </button>
          }
          <div className="price-usd">${this.priceUsd()}USD</div>
        </div>
      </article>
    );
  }

}

export default PresaleItem;