/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBadge = /* GraphQL */ `
  query GetBadge($id: ID!) {
    getBadge(id: $id) {
      id
      legacyId
      title
      achievementTitle
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBadges = /* GraphQL */ `
  query ListBadges(
    $filter: ModelBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legacyId
        title
        achievementTitle
        url
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getKongpanion = /* GraphQL */ `
  query GetKongpanion($id: ID!) {
    getKongpanion(id: $id) {
      id
      legacyId
      title
      description
      userKongpanionsCount
      chipValue
      url
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listKongpanions = /* GraphQL */ `
  query ListKongpanions(
    $filter: ModelKongpanionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKongpanions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legacyId
        title
        description
        userKongpanionsCount
        chipValue
        url
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStickerPack = /* GraphQL */ `
  query GetStickerPack($id: ID!) {
    getStickerPack(id: $id) {
      id
      legacyId
      title
      description
      distributionType
      price
      shinyOwnedCount
      commonOwnedCount
      backgroundCss
      sku
      visibleAt
      createdAt
      updatedAt
      owner
      stickers {
        items {
          id
          legacyId
          title
          url
          shiny
          stickerPackId
          createdAt
          updatedAt
          stickerPack {
            id
            legacyId
            title
            description
            distributionType
            price
            shinyOwnedCount
            commonOwnedCount
            backgroundCss
            sku
            visibleAt
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const listStickerPacks = /* GraphQL */ `
  query ListStickerPacks(
    $filter: ModelStickerPackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStickerPacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legacyId
        title
        description
        distributionType
        price
        shinyOwnedCount
        commonOwnedCount
        backgroundCss
        sku
        visibleAt
        createdAt
        updatedAt
        owner
        stickers {
          items {
            id
            legacyId
            title
            url
            shiny
            stickerPackId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSticker = /* GraphQL */ `
  query GetSticker($id: ID!) {
    getSticker(id: $id) {
      id
      legacyId
      title
      url
      shiny
      stickerPackId
      createdAt
      updatedAt
      stickerPack {
        id
        legacyId
        title
        description
        distributionType
        price
        shinyOwnedCount
        commonOwnedCount
        backgroundCss
        sku
        visibleAt
        createdAt
        updatedAt
        owner
        stickers {
          items {
            id
            legacyId
            title
            url
            shiny
            stickerPackId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listStickers = /* GraphQL */ `
  query ListStickers(
    $filter: ModelStickerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStickers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legacyId
        title
        url
        shiny
        stickerPackId
        createdAt
        updatedAt
        stickerPack {
          id
          legacyId
          title
          description
          distributionType
          price
          shinyOwnedCount
          commonOwnedCount
          backgroundCss
          sku
          visibleAt
          createdAt
          updatedAt
          owner
          stickers {
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      walletId
      kongUserId
      kongUserName
      kreds
      mobileSpendLevel
      totalKredsPurchased
      totalDollarsSpent
      lastGamePlayedAt
      lastPurchaseDate
      parentalConsent
      kongMetaLevel
      email
      isLinked
      premium
      wallet {
        unique_count
        stackable_type_count
        coins {
          symbol
          balance
        }
        stackables {
          stackable_type_id
          balance
          metadata {
            asset_category
            name
            price
            sku
            quantity
          }
          key
        }
        uniques {
          unique_id
          key
          unique_type
          metadata
        }
      }
      transactions {
        transaction_id
        user_id_from
        user_id_to
        type
        data {
          amount
          token_id
        }
        timestamp
      }
      exchangeUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        walletId
        kongUserId
        kongUserName
        kreds
        mobileSpendLevel
        totalKredsPurchased
        totalDollarsSpent
        lastGamePlayedAt
        lastPurchaseDate
        parentalConsent
        kongMetaLevel
        email
        isLinked
        premium
        wallet {
          unique_count
          stackable_type_count
          coins {
            symbol
            balance
          }
          stackables {
            stackable_type_id
            balance
            key
          }
          uniques {
            unique_id
            key
            unique_type
            metadata
          }
        }
        transactions {
          transaction_id
          user_id_from
          user_id_to
          type
          data {
            amount
            token_id
          }
          timestamp
        }
        exchangeUrl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const usersByKongId = /* GraphQL */ `
  query UsersByKongId(
    $kongUserId: Int
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByKongId(
      kongUserId: $kongUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        walletId
        kongUserId
        kongUserName
        kreds
        mobileSpendLevel
        totalKredsPurchased
        totalDollarsSpent
        lastGamePlayedAt
        lastPurchaseDate
        parentalConsent
        kongMetaLevel
        email
        isLinked
        premium
        wallet {
          unique_count
          stackable_type_count
          coins {
            symbol
            balance
          }
          stackables {
            stackable_type_id
            balance
            key
          }
          uniques {
            unique_id
            key
            unique_type
            metadata
          }
        }
        transactions {
          transaction_id
          user_id_from
          user_id_to
          type
          data {
            amount
            token_id
          }
          timestamp
        }
        exchangeUrl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTokenizedUserUrl = /* GraphQL */ `
  query GetTokenizedUserUrl($id: ID!) {
    getTokenizedUserUrl(id: $id)
  }
`;
export const getWallet = /* GraphQL */ `
  query GetWallet($walletId: ID!) {
    getWallet(walletId: $walletId) {
      unique_count
      stackable_type_count
      coins {
        symbol
        balance
      }
      stackables {
        stackable_type_id
        balance
        metadata {
          asset_category
          name
          price
          sku
          quantity
        }
        key
      }
      uniques {
        unique_id
        key
        unique_type
        metadata
      }
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory {
    getInventory {
      stackables {
        stackable_type_id
        balance
        metadata {
          asset_category
          name
          price
          sku
          quantity
        }
        key
      }
      uniques {
        unique_id
        key
        unique_type
        metadata
      }
    }
  }
`;
