import { User } from '../API';

const UserHelpers = {
  getBalance(u?:User):number {
    let balance:number = 0;
    if (u?.wallet?.coins) {
      u.wallet.coins.forEach(coinType => {
        if (coinType?.symbol === "COIN" && coinType.balance) {
          balance = parseInt(coinType.balance);
        }
      })
    }
    return Math.trunc(balance / 100000000000);
  }
}

export default UserHelpers;