import { Component, ReactElement } from 'react';

import PresaleItem, { PresaleItemData } from './PresaleItem';

import UserHelpers from '../helpers/UserHelpers';
import { Stackable, User } from "../API";


interface PurchaseItemModalContentProps {
  itemData?: PresaleItemData;
  stackableData?: Stackable;
  userData?: User;
  errorMsg?: String;
  purchaseItemFunction: (item:Stackable) => void;
  closeModalFunction: () => void;
  openSigninFunction: () => void;
  goToExchangeFunction: () => void;
}

interface PurchaseItemModalContentState {
  processing: boolean;
}

class PurchaseItemModalContent extends Component<PurchaseItemModalContentProps, PurchaseItemModalContentState> {
  //private wrapperRef: React.RefObject<HTMLDivElement>;
  constructor(props: PurchaseItemModalContentProps) {
    super(props);
    this.state = {
      processing: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.purchaseItem = this.purchaseItem.bind(this);
    this.itemCost = this.itemCost.bind(this);
    this.walletBalanceMessage = this.walletBalanceMessage.bind(this);
    this.buyButton = this.buyButton.bind(this);
  }

  handleClose() {
    this.props.closeModalFunction();
  }

  async purchaseItem(event: React.MouseEvent) {
    event.preventDefault();
    this.setState({
      processing: true
    });
    if (this.props.stackableData)
      await this.props.purchaseItemFunction(this.props.stackableData);
    this.setState({
      processing: false
    });
  }

  itemCost():number {
    if (this.props.stackableData?.metadata?.price === null) return 1;//THIS IS FOR TEST SKU

    if (this.props.stackableData?.metadata?.price) return parseInt(this.props.stackableData?.metadata?.price);
    return 1000;
  }

  walletBalanceMessage():ReactElement {
    if (UserHelpers.getBalance(this.props.userData) >= this.itemCost()) {
      // Use this instead if user has wallet balance.
      return <p className="helper wallet-balance">Wallet Balance: {UserHelpers.getBalance(this.props.userData)} Koins</p>;
    } 
    // Use if user does not have wallet balance.
    return <p className="helper">You'll be taken to our partner's checkout flow to complete your purchase.</p>;
  }

  buyButton():ReactElement {
    // Use this instead if user has wallet balance.
    if(this.props.userData === undefined)
    {
      return <a href = "#" className = "primary-button buy-button comp-button" onClick={this.props.openSigninFunction}> Sign In To Buy</a >;
    } else if (UserHelpers.getBalance(this.props.userData) >= this.itemCost()) {
      return <a href = "#" className = "primary-button buy-button comp-button" onClick={this.purchaseItem}> Complete Purchase</a >;
    }
    // Use if user does not have wallet balance.
    return <a href="#" className="primary-button buy-button ext-button" onClick={this.props.goToExchangeFunction}>Exchange for Koins</a>;
  }

  render() {
    if (this.props.itemData == null)
      return null;

    return (
        <section className="purchase-review">
          <span className="close" onClick={this.handleClose}>&times;</span>
          <h1>Review your purchase</h1>

          <PresaleItem 
            key={this.props.itemData.name}
            userStackablesData={this.props.userData?.wallet?.stackables}
            itemData={this.props.itemData}
            stackableData={this.props.stackableData}
            showBuy={false}/>

          <this.walletBalanceMessage />


          {
            this.props.errorMsg &&
            <p className="helper wallet-balance purchase-error">Error: {this.props.errorMsg}</p>
          }
          { 
            !this.state.processing &&
            <button className="buy">
              <this.buyButton />
            </button>
          }
          {
            this.state.processing &&
            <p className="helper wallet-balance">Processing...</p>
          }

        </section>
    );
  }

}

export default PurchaseItemModalContent;