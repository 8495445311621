import React, { Component } from 'react';
import { AmplifySignUp } from '@aws-amplify/ui-react';

interface SignUpUIProps {}

interface SignUpUIState {}

class SignUpUI extends Component<SignUpUIProps, SignUpUIState> {
  constructor(props: SignUpUIProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          {
            type: 'username',
            inputProps: { required: true, autocomplete: 'username' },
          },
          {
            type: 'password',
            inputProps: { required: true, autocomplete: 'new-password' },
          },
          {
            type: 'email',
            inputProps: { required: true, autocomplete: 'email' },
          },
        ]}
      />
    );
  }
}

export default SignUpUI;
