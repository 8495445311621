import React, { Component } from 'react';
import { Stackable } from '../API';
import {PresaleItemData} from "./PresaleItem";
import { GameSectionData} from './GameSection';

const gamesData = require("../data/gamesData.json");

interface StackableItemProps {
  stackable?: (Stackable | null);
}

interface StackableItemState {
}

class StackableItem extends Component<StackableItemProps, StackableItemState> {
  constructor(props: StackableItemProps) {
    super(props);
    this.state = {
    };
  
    this.stackableImage = this.stackableImage.bind(this);
    this.allStackables = this.allStackables.bind(this);

  }

  allStackables(): PresaleItemData[] {
    const allnfts = gamesData.map((game: GameSectionData) => {
      return require("../data/" + game.data);
    });
    return allnfts.flat();
  }


  stackableImage(): string {
    const stackable = this.allStackables().find(obj => {
      return obj.sku === this.props.stackable?.metadata?.sku;
    });
    var img = "bh-clothes.png"; //default value incase its missing
    if(stackable != null) {
      img = stackable.img;
    }
    return require("../images/item-assets/" + img).default;
  } 
 

  render() {

    return (
      <article className="stackable">
        <div className="stackable-details">
          <h1>{this.props.stackable?.metadata?.name || "Unknown Name"}</h1>
          <p className="stackable-rarity">You Own {this.props.stackable?.balance || "0"}!</p>
          <img src={this.stackableImage()} alt={this.props.stackable?.metadata?.name ?? "NFT"} className='profile-stackable-image'/>
        </div>
        
      </article>
    );
  }

}

export default StackableItem;