import { Component } from 'react';

interface HomePageProps {
}

interface HomePageState {
}


class HomePage extends Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = {
    };

  }

  render() {
    return (
      <div>
        <section className="hero">
          <h3 className="subtitle"> THE NEXT STAGE OF GAMING</h3>
          <h1 className="title">KONGREGATE.io</h1>
          <div className="videoWrapper">
            <iframe title="trailer" width="764" height="430" src="https://www.youtube.com/embed/cic57cswxdA?autoplay=1&mute=1&controls=0&rel=0&loop=1&playlist=cic57cswxdA" frameBorder="0"></iframe>
          </div>
        </section>
        {this.props.children}
      </div>
    );
  }

}

export default HomePage;