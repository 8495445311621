import { Component } from 'react';
import { Inventory, Stackable, } from '../API';

import StackableHelper from '../helpers/StackableHelpers'

import PresaleItem, { PresaleItemData } from './PresaleItem';

interface GameSectionProps {
  gameData: GameSectionData;
  userStackableData?: (Stackable | null)[] | null | undefined;
  devWallet?: Inventory;
  openModalFunction: (itemData: PresaleItemData) => void;
}

interface GameSectionState {
}

export type GameSectionData = object & {
  id: number;
  name: string;
  class: string;
  data: string;
}

class GameSection extends Component<GameSectionProps, GameSectionState> {
  constructor(props: GameSectionProps) {
    super(props);
    this.state = {
    };
    
    this.classes = this.classes.bind(this);
    this.itemsData = this.itemsData.bind(this);
  }

  classes() {
    return "game " + this.props.gameData.class;
  }

  itemsData():PresaleItemData[] {
    return require("../data/" + this.props.gameData.data);
  }

  render() {
    return (
      <section className={this.classes()} id={this.props.gameData.class}>
        <h2>{this.props.gameData.name}</h2>
        <div className="game-items">
          {/* Repeat article for each item. */}
          {this.itemsData().map(data => (<PresaleItem 
                                                key={data.name} 
                                                itemData={data}
                                                styleClass={this.props.gameData.class}
                                                userStackablesData={this.props.userStackableData} 
                                                stackableData={StackableHelper.stackableDataForSKU(this.props.devWallet?.stackables, data.sku)}
                                                openModalFunction={this.props.openModalFunction}
                                                showBuy={true}/>))}
        </div>
      </section>
    );
  }

}

export default GameSection;