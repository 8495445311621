import { Component } from 'react';

interface FAQPageProps {}

interface FAQPageState {}

class FAQPage extends Component<FAQPageProps, FAQPageState> {
  constructor(props: FAQPageProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="centered-main-div">
        <h2>KONGREGATE.io FAQ</h2>
        <br />
        <p>
          <strong>
            What is KONGREGATE.io and why is it different from Kongregate.com?
          </strong>
          <br />
          KONGREGATE.io is a celebration and evolution of Kongregate’s 15 years
          in the gaming space and will redefine what a web gaming platform is
          for today’s players. Our goal is to build a selection of the best
          competitive and .io style games the market has to offer, all in one
          place.
        </p>
        <br />
        <p>
          <strong>What will happen to my Kongregate.com account?</strong>
          <br />
          Your Kongpanions and Stickers from Kongregate.com will be convertible
          to NFTs on KONGREGATE.io later this year. Your badges on
          Kongregate.com will carry forward automatically on KONGREGATE.io if
          you connect accounts, as will your progress in Animation Throwdown,
          Bit Heroes and Spellstone.
        </p>
        <br />
        <p>
          <strong>What are NFT’s?</strong>
          <br />
          More than just unique collectibles, each NFT will unlock special
          rewards in its respective game and in the upcoming KONGREGATE.io
          experience. Our first set of NFTs celebrate two of our most loved
          games: the hit dungeon crawler Bit Heroes, and the inimitable
          Surviv.io. And as a special treat for longtime Kongregate fans, we
          have none other than the long awaited, near mythical Kongpanion…
          Stabby!
        </p>
        <br />
        <p>
          <strong>When will I be able to use my NFTs in game?</strong>
          <br />
          Later this year, your game accounts will be connected to your
          KONGREGATE.io account and you will be among the first to show off your
          NFTs in game!
        </p>
        <br />
        <p>
          <strong>What are KOINS?</strong>
          <br />
          KONGREGATE.io is introducing “Koins” which will be usable across
          multiple Kongregate game properties. Koins can be used at this time to
          purchase NFT’s for Surviv.io, Bit Heroes, Kongregate Stickers and
          Kongpanions, and more.
        </p>
        <br />
        <p>
          <strong>How do I get KOINS?</strong>
          <br />
          Create and link a Forte Exchange account to your Kongregate.io account
          to purchase and manage your Koin balance. Learn more about the Forte
          Exchange here:
          <br />
          https://exchange.forte.io/portal/faq
        </p>
        <br />
        <p>
          <strong>How do I purchase the NFTs?</strong>
          <br />
          After linking to your Exchange account, you will be able to redeem
          KOINS for NFTs. There is no limit to the quantity you can exchange.
        </p>
        <br />
        <p>
          <strong>What if the NFT I want is sold out?</strong>
          <br />
          All items sold in the KONGREGATE.io store are sold on a first-come,
          first-served basis. This means that there is a chance that some NFTs
          may sell out quickly - but never fear! The KONGREGATE.io store will be
          announcing more cool items over the upcoming weeks.
        </p>
        <br />
        <p>
          <strong>When can I purchase NFTs for my favorite game?</strong>
          <br />
          Check back in to KONGREGATE.io often as we will be adding more games
          and NFTs soon!
        </p>
        <br />
        <h2>Bit Heroes</h2>
        <br />
        <p>
          <strong>What is Bit Heroes?</strong>
          <br />
          Bit Heroes is a retro-dungeon crawling adventure with PvP, Pets,
          Mounts, Guilds, and more! Explore and loot hundreds of randomly
          generated dungeons and fight monsters in epic turn-based combat.
        </p>
        <br />
        <p>
          <strong>
            What am I able to buy for Bit Heroes on KONGREGATE.io?
          </strong>
          <br />
          We are offering two unique items at this time, Nicholson’s Fast
          Trigger (a mount), and Tony’s Hat & Gloves (an outfit)
        </p>
        <br />
        <p>
          <strong>What can I do with NFTs in Bit Heroes?</strong>
          <br />
          Nicholson’s Fast Trigger (a mount) has several in-game bonuses
          affecting Power, Stamina, Agility & Special Abilities. Tony’s Hat &
          Gloves is a cosmetic item that you can equip in game.
        </p>
        <br />
        <h2>Kongregate.com</h2>
        <br />
        <p>
          <strong>What is a Kongpanion?</strong>
          <br />
          Kongpanions are original characters that players on Kongregate.com
          collected by earning "badges of the day". We have a huge collection of
          Kongpanions that have been released over the last seven years!
        </p>
        <br />
        <p>
          <strong>Who is Stabby?</strong>
          <br />
          The most-requested Kongpanion is now available as a NFT through
          KONGREGATE.io. Be among the first to add Stabby to your collection
          today!
        </p>
      </div>
    );
  }
}

export default FAQPage;
