import React, { Component } from 'react';

import { User } from '../API';
import UserHelpers from '../helpers/UserHelpers';
import StackableItem from './StackableItem';

interface UserInfoModalContentProps {
  showUserInfoData?: User;
  closeModalFunction: () => void;
}

interface UserInfoModalContentState {
}

class UserInfoModalContent extends Component<UserInfoModalContentProps, UserInfoModalContentState> {
  //private wrapperRef: React.RefObject<HTMLDivElement>;
  constructor(props: UserInfoModalContentProps) {
    super(props);
    this.state = {
    };

    this.handleClose = this.handleClose.bind(this);
    this.renderInventory = this.renderInventory.bind(this);

  }

  handleClose() {
    this.props.closeModalFunction();
  }

  renderInventory(): JSX.Element | JSX.Element[]| undefined{
    if(this?.props?.showUserInfoData?.wallet?.stackables?.length === 0) {
      return <p className="helper wallet-balance">No Items</p>;
    } else {
      return this?.props?.showUserInfoData?.wallet?.stackables?.map(stackable => (<StackableItem 
        key={stackable?.metadata?.sku} 
        stackable={stackable}
        />));
    }
    
  }

  render() {
    if (this.props.showUserInfoData === undefined) {
      return null;
    } else {
      return (
      <section className="purchase-review">
        <span className="close" onClick={this.handleClose}>&times;</span>
        <p className="helper wallet-balance">{this.props.showUserInfoData?.username}</p>
        <p className="helper wallet-balance">Wallet Balance: {UserHelpers.getBalance(this.props.showUserInfoData)} Koins</p>
        <p className="helper wallet-balance">Inventory:</p>
        {this.renderInventory()}        
      </section>
    
      );
    }
  }

}

export default UserInfoModalContent;