import React, { Component } from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { AmplifyAuthContainer, AmplifyAuthenticator } from '@aws-amplify/ui-react';

import SignUpUI from './SignUpUI'

interface SignInModalContentProps {
  signInView: AuthState.SignIn | AuthState.SignUp | undefined;
  closeModalFunction: () => void;
}

interface SignInModalContentState {
}

class SignInModalContent extends Component<SignInModalContentProps, SignInModalContentState> {
  //private wrapperRef: React.RefObject<HTMLDivElement>;
  constructor(props: SignInModalContentProps) {
    super(props);
    this.state = {
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.closeModalFunction();
  }

  render() {
    if (this.props.signInView === undefined)
      return null;

    return (
      <section className="purchase-review">
        <span className="close" onClick={this.handleClose}>&times;</span>
        <AmplifyAuthContainer>
          <AmplifyAuthenticator initialAuthState={this.props.signInView}>
            <SignUpUI />
          </AmplifyAuthenticator>
        </AmplifyAuthContainer>
      </section>
    );
  }

}

export default SignInModalContent;