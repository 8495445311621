import { Component, ReactElement } from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { Link } from "react-router-dom";
import { Auth } from '@aws-amplify/auth'
import { User } from '../API'
import UserHelpers from '../helpers/UserHelpers';

interface NavBarProps {
  openSignInModal: () => void;
  openSignUpModal: () => void;
  openUserInfoModal: () => void;
  goToExchangeFunction: () => void;
  authState: AuthState;
  userData?: User;
}

interface NavBarState {
}

class NavBar extends Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);
    this.state = {
    };

    this.signOut = this.signOut.bind(this);
    this.thirdElement = this.thirdElement.bind(this);
    this.fourthElement = this.fourthElement.bind(this);
    this.walletBalanceDisplay = this.walletBalanceDisplay.bind(this);
  }

  async signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  thirdElement():ReactElement {
    if (this.props.authState === AuthState.SignedIn) {
      return (
        //<a href = "#" onClick={this.props.openUserInfoModal}>Welcome, {this.props.username}</a >
        <a href="/#" onClick={this.props.openUserInfoModal}>Welcome, {this.props.userData?.username}</a >
      );
    }
    return (
      <a href="/#" data-test="sign-in-link" onClick={this.props.openSignInModal}>Sign in</a>
    );
  }

  fourthElement():ReactElement {
    if (this.props.authState === AuthState.SignedIn) {
      return (
        < a href="/#" data-test="sign-out-link" onClick={this.signOut}>Sign Out</a >
      );
    }
    return (
      <a href="/#" onClick={this.props.openSignUpModal} className="button">Create Account</a>
    );
  }

  walletBalanceDisplay():ReactElement|null {
    if (this.props.authState === AuthState.SignedIn) {
      return <li><a href="/#" style={{ display: "inline", verticalAlign: "sub" }}>Balance: {UserHelpers.getBalance(this.props.userData).toString()}<img style={{ display: "inline", verticalAlign: "middle", padding: "2px" }} src={require("../images/icons/KoinIcon_24x24.png").default} alt="Koin" /></a>&nbsp;<a className="buy-more-button primary-button buy-button comp-button" href="#" onClick={this.props.goToExchangeFunction}>Buy More</a></li>;
    }
    return null;
  }

  render() {
    return (
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <this.thirdElement />
          </li>
            <this.walletBalanceDisplay/>
          <li>
            <this.fourthElement />
          </li>
        </ul>
      </nav>
    );
  }

}

export default NavBar;